<template>
  <v-container
    class="drawer-detection-container"
    fluid
    :class="drawer ? 'drawer-open' : 'drawer-closed'"
  >
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            class="team-roles-table"
            :headers="headers"
            :items="displayTeamRoles"
            :loading="currentPageLoading"
            :server-items-length="totalCount"
            @update:options="paginate($event)"
            :footer-props="footerProps"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Team Roles</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="role-add-btn"
                  @click="createOrUpdateRole()"
                >
                  New Role
                </v-btn>
                <ConfirmationDialog ref="confirmationDialog" />
                <TeamRoleDialog ref="teamRoleDialog" />
              </v-toolbar>
            </template>

            <template v-slot:[`item.name`]="{ item }">
              {{item.name}}
            </template>

            <template v-slot:[`item.roleActions`]="{ item }">
              <div class="entity-permissions">
                <div v-for="(action, i) in item.actions" :key="i">
                  <v-chip class="ma-1">{{action.text}}</v-chip>
                </div>
              </div>
            </template>

            <template v-slot:[`item.entityPermissions`]="{ item }">
              <div class="entity-permissions">
                <div class="ma-1" v-for="(it, i) in item.entityPermissions" :key="i">
                  <EntityPermissionsChip :pEntityPermission="it"/>
                </div>
              </div>
            </template>

            <template v-slot:[`item.filePermissions`]="{ item }">
              <div class="file-permissions">
                <div class="ma-1" v-for="(it, i) in item.filePermissions" :key="i">
                  <FilePermissionsChip :pFilePermission="it"/>
                </div>
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex align-center justify-center">
                <v-btn
                  icon
                  class="team-role-edit-btn"
                  :disabled="currentPageLoading"
                  @click="createOrUpdateRole(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  icon
                  class="team-role-delete-btn white--text"
                  color="red"
                  :disabled="currentPageLoading"
                  @click="openDeleteRoleDialog(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { Vue } from "vue-property-decorator";
import { dismissAction, logAndExtractMessage, paginateTable } from "@/utils/utils";

export default {
  name: "TeamRoles",

  components: {
    EntityPermissionsChip: () => import("@/components/team-roles/EntityPermissionsChip"),
    FilePermissionsChip: () => import("@/components/team-roles/FilePermissionsChip"),
    TeamRoleDialog: () => import("@/components/team-roles/TeamRoleDialog"),
    ConfirmationDialog: () => import("@/components/dialogs/ConfirmationDialog"),
  },

  data: () => ({
    localStorageTableSizeKey: "team.roles.table.size",
    tableInited: false,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    headers: [
      { text: "Name", value: "name", sortable: false },
      { text: "Entity Permissions", value: "entityPermissions", sortable: false },
      { text: "File Permissions", value: "filePermissions", sortable: false },
      { text: "Role Actions", value: "roleActions", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState("teamRolesModule", [
      "currentPageLoading",
      "currentPage",
      "displayTeamRoles",
      "totalCount",
    ]),
    ...mapState("coreModule", ["drawer"]),
  },

  methods: {
    paginate(data) {
      data.itemsPerPage = paginateTable(
        data,
        this.localStorageTableSizeKey,
        this.tableInited,
      );
      const currentPage = {
        index: data.page - 1,
        size: data.itemsPerPage,
      };

      this.loadPage(currentPage);
      this.tableInited = true;
    },

    async loadPage(currentPage) {
      try {
        await this.$store.dispatch("teamRolesModule/loadPage", currentPage);
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },

    createOrUpdateRole(role) {
      this.$refs.teamRoleDialog.openDialog(role);
    },

    openDeleteRoleDialog(role) {
      this.$refs.confirmationDialog.openDialog({
        header: "Delete confirmation",
        text: `Are you sure you want to delete "${role.name}" role?`,
        submitText: "Delete",
        submitColor: "red",
        onSubmit: async () => {
          try {
            await this.$store.dispatch("teamRolesModule/deleteTeamRole", role);
          } catch (e) {
            Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.entity-permissions {
  display: flex;
  align-items: center;
  max-width: 400px;
  flex-wrap: wrap;
}
</style>
